import Vue from 'vue/dist/vue.esm.js';

Vue.component('review-modal', {
    template: '#review-modal',
    delimiters: ['[[', ']]'],
    props: {
        apiKey: {
            required: true,
            type: String,
        },
    },

    mounted() {

        window.ToolShopRecaptchaLoaded = this.recaptchaLoaded;

    },

    methods: {
        recaptchaLoaded() {
            let _self = this;

            if (this.$refs.captchaField) {
                grecaptcha.execute().then(function (token) {
                    _self.$refs.captchaField.value = token;
                })
            }
        },
        favoriteAdded() {
            this.count = this.count + 1;
        },
        favoriteRemoved() {
            this.count = this.count - 1;
        },

    }
});