import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source" ];

    copy(event) {
        event.preventDefault();

        let tempInput = document.createElement("input");
        tempInput.style.position = "absolute";
        tempInput.style.left = "-1000px";
        tempInput.style.top = "-1000px";
        tempInput.value = this.text;

        document.body.appendChild(tempInput);
        tempInput.select();

        document.execCommand("copy");
        document.body.removeChild(tempInput);

        flash('Copied ' + this.text + ' to the clipboard.', 'success');
    }

    get text() {
        return this.sourceTarget.value;
    }
}