import Vue from 'vue/dist/vue.esm.js';

Vue.component('countries-filter', {
    template: '#countries-filter',
    delimiters: ['[[', ']]'],
    props: {
        countries: {
            required: true,
            type: Array,
        },
        selectedCountry:  {
            required: false,
        },
    },

    data() {
        return {
            showAll: false,
            limit: 11,
        }
    },


    methods: {
        toggleAllCountries() {
            this.showAll = !this.showAll;
        },
        selectCountry(country) {
            this.selectedCountry = country.id;

            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("country", country.id);
            window.location.search = searchParams.toString();
        },
        resetFilter(){
            let searchParams = new URLSearchParams(window.location.search);
            if(searchParams.has('country')){
                searchParams.delete('country');
            }

            searchParams.delete('page');
            history.replaceState(null, null, ' ');

            window.location.search = searchParams.toString();
        }
    }
    ,
    computed: {
        countriesToShow() {

            if (this.showAll) {
                return this.countries;
            }

            return this.countries.slice(0, this.limit);
        }

    }
});