import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        let apiKey = this.element.getAttribute('data-api-key');
        let capthaInput = this.element;
        grecaptcha.ready(function () {
            grecaptcha.execute(apiKey, {action: 'homepage'}
        ).then(function (token) {
                capthaInput.value = token;
            })
        });
    }
}