import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        this.id = this.element.getAttribute('id');

        this.createStyleTag(this.data.get('image'));
    }

    createStyleTag() {
        let styleElement = document.createElement('style');

        let css = document.createTextNode(`
            ${this.imageCSS}
            ${this.imageMobileCSS}
        `);
        styleElement.append(css);

        document.querySelector('head').append(styleElement);
    }

    get imageCSS() {
        if (this.data.has('image')) {
            return `
            #${this.id} {
                background-image: url(${this.data.get('image')});
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
            `;
        }

        return '';
    }

    get imageMobileCSS() {
        if (this.data.has('imageMobile')) {
            return `
            @media(max-width:576px) {
                #${this.id} {
                    background-image: url(${this.data.get('imageMobile')});
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
            `;
        }

        return '';
    }
}