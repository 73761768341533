import Vue from 'vue/dist/vue.esm.js';

Vue.component('cart', {
    template: `#cart`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeRowUrl: {
            required: true,
            type: String,
        },
        incrementUrl: {
            required: true,
            type: String,
        },
        decrementUrl: {
            required: true,
            type: String,
        },
        promocodeUrl: {
            required: true,
            type: String,
        },
        removePromocodeUrl: {
            required: true,
            type: String,
        },
        freeDeliveryOver: {
            required: true,
            type: Number,
        },
        minimalOrderValue: {
            required: true,
            type: Number,
        }
    },

    mounted() {
        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('item-removed', this.cartUpdated);
    },

    data() {
        return {
            cart: this.initialCart,
            showPromocode: false,
            hasPromocode: !!this.initialCart.promocode,
            promocode: this.initialCart.promocode,
            promocodeError: null,
            quantityAlert: {
                visible: false,
                productName: '',
            },
        }
    },

    methods: {
        cartUpdated(data) {
            this.cart = JSON.parse(data);
        },

        removeRow(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.removeRowUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('item-removed', result.cart);

                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CartPage',
                    }
                    EventManager.fire('gtm-event', event);
                },
            });
        },
        addVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.variantId, engraving: row.hasEngraving},
                url: _self.incrementUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success !== false) {
                        _self.quantityAlert.visible = false;
                        EventManager.fire('item-added', result.cart);
                        let event = {
                            event: 'eec.add',
                            eventData: result.variant,
                            action: 'CartPage',
                        }
                        EventManager.fire('gtm-event', event);

                    } else {
                        _self.quantityAlert.visible = true;
                        _self.quantityAlert.productName = row.name;
                    }
                },
            });
        },
        decrementVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.decrementUrl,
                method: 'POST',
                success: function (result) {
                    _self.quantityAlert.visible = false;
                    EventManager.fire('item-removed', result.cart);
                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CartPage',
                    }
                    EventManager.fire('gtm-event', event);
                },
            });
        },

        incrementRowQuantity(row) {
            if (row.quantity > 99) {
                return;
            }
            this.addVariantQuantity(row);

        },

        decrementQuantity(row) {
            if (row.quantity === 1) {
                return;
            }
            this.decrementVariantQuantity(row);
        },
        formattedRowPrice(row) {
            return this.formattedPrice(row.totalPrice);
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        qualifiesForFreeDelivery() {
            return this.cart.total >= this.freeDeliveryOver;
        },

        togglePromocodeField() {
            this.showPromocode = !this.showPromocode;
        },
        applyPromocode() {
            let _self = this;
            $.ajax({
                data: {code: _self.promocode},
                url: _self.promocodeUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success === false) {
                        _self.promocodeError = result.error;
                    } else {
                        _self.promocodeError = null;
                        _self.hasPromocode = true;
                        EventManager.fire('item-added', result);
                    }
                },
            });
        },

        removePromocode() {
            let _self = this;
            $.ajax({
                url: _self.removePromocodeUrl,
                method: 'POST',
                success: function (result) {
                    _self.hasPromocode = false;
                    EventManager.fire('item-added', result);
                },
            });
        },


        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index, attribute] of attributes.entries()) {
                if (index !== 0) {
                    str += ', '
                }
                if (attribute.showUnit) {
                    str += attribute.unit + ' x '
                }
                str += attribute.attribute ;

                if (attribute.isColor) {
                    str += ': ' + '<strong>' +  attribute.label + '</strong>';
                } else {
                    str += ': ' + '<strong>' + attribute.value + '</strong>';
                }

            }
            return str;
        }
    },

    computed: {
        formattedTotal() {
            return this.formattedPrice(this.cart.total)
        },
        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal - (this.cart.originalTotal / 6))
        },
        formattedVAT() {
            return this.formattedPrice(this.cart.originalTotal / 6)
        },
        amountToFreeShipping() {
            return this.formattedPrice(this.freeDeliveryOver - this.cart.total);
        },
        formattedMinimalOrder() {
            return this.formattedPrice(this.minimalOrderValue);
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        }
    }
});