import Vue from 'vue/dist/vue.esm.js';

Vue.component('paypal-buttons', {
    template: '#paypal-buttons',
    delimiters: ['[[', ']]'],
    props: {
        clientId: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        purchase: {
            required: true,
            type: Object,
        },
        updateDataUrl: {
            required: true,
            type: String,
        },
        captureUrl: {
            required: true,
            type: String,
        },
    },

    created() {
        this.isLoading = true;
        this.createScript();
    },

    mounted() {
        //EventManager.listen('filter-applied', this.filterApplied);
    },

    data() {
        return {
            isLoading: false,
        }
    },
    methods: {

        async postData(url = '', data = {}) {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'

                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
            return response.json();
        },

        createScript() {
            const script = document.createElement("script");
            script.src = "https://www.paypal.com/sdk/js?client-id=" + this.clientId + "&currency=" + this.currency;
            script.addEventListener("load", this.setLoaded);
            document.body.appendChild(script);
        },

        setLoaded() {
            this.isLoading = false;
            let _self = this;
            window.paypal.Buttons({
                createOrder: function (data, actions) {
                    _self.isLoading = true;
                    return actions.order.create(_self.purchase);
                },
                onCancel: function (data) {
                    _self.isLoading = false;
                },

                onApprove: function (data, actions) {

                    _self.postData(_self.updateDataUrl, data);

                    return actions.order.capture().then(function (details) {

                        _self.postData(_self.captureUrl, details).then(function (response) {

                            if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                            _self.isLoading = false;
                        });


                    });
                },
            }).render('#paypal-button-container');
        }
    }
});