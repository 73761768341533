import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['arrow', 'subMenus'];

    initialize() {
        let subMenus = this.subMenusTarget.querySelectorAll('li');

        subMenus.forEach(item => {
            let route = item.dataset.route;
            if (window.location.href.includes(route)) {
                this.toggle();
            }
        });
    }

    toggle() {
        this.arrowTarget.classList.toggle('fa-angle-right');
        this.arrowTarget.classList.toggle('fa-angle-down');

        this.subMenusTarget.classList.toggle('d-none');
    }
}