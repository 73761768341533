import Vue from 'vue/dist/vue.esm.js';

Vue.component('delete-module-item', {
    template: `  <button type="submit" @click="deleteAction($event)" :form="formId" class="btn btn-link btn-lg"><span
                                                class="fa fa-minus-circle"></span>
                                    </button>`,
    props: {
        moduleItemId: {
            required: true,
            type: String
        },
        confirmationMessage: {
            required: false,
            type: String,
            default: 'Please, confirm you want to delete this module!'
        },
    },
    computed: {
        formId() {
            return "remove_page_item_" + this.moduleItemId;
        }
    },
    methods: {
        deleteAction(event) {
            if(!confirm(this.confirmationMessage)){
              event.preventDefault();
            }
        }
    }
});