import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['arrow', 'answer'];

    connect() {
        if (this.data.has('open')) {
            this.toggleAnswer();

            this.element.scrollIntoView({behavior: "smooth", block: "center"});
        }
    }

    toggleAnswer() {
        this.arrowTarget.classList.toggle('fa-angle-right');
        this.arrowTarget.classList.toggle('fa-angle-down');

        this.answerTarget.classList.toggle('not-visible');
    }
}