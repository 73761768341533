import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['email'];

    subscribe(event) {
        let email = this.emailTarget.value,
            url = this.data.get('subscribeUrl'),
            _self = this;

        event.preventDefault();

        $.ajax({
            method: 'POST',
            url: url,
            data: {'email': email},
            success(response) {
                flash(response, 'success');
                _self.emailTarget.value = '';
            },
            error(error) {
                flash(error['responseJSON'], 'error');
            }
        });
    }
}