import {Controller} from "stimulus"

export default class extends Controller {
    //static targets = ['arrow', 'answer'];

    connect() {

        this.element.addEventListener('change', this.selectOnChange)
    }

    selectOnChange(event) {

        let element = event.target;
        let limit = element.value;

        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("limit", limit);
        window.location.search = searchParams.toString();
    }

}