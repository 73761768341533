import PerfectScrollbar from 'perfect-scrollbar';

$(document).ready(function () {

    $(window).scroll(function () {
        if ($(this).scrollTop() > 500) {
            $('.scroll-top').addClass('shw');
        } else {
            $('.scroll-top').removeClass('shw');
        }
    });

    $('.scroll-top').click(function () {
        $('html, body').animate({scrollTop: 0}, 360);
        return false;
    });

    $('.carousel').carousel({
        interval: 5000,
        touch: true
    });

    $(window).scroll(function () {
        var sticky = $('header'),
            scroll = $(window).scrollTop();

        if (scroll >= 100)
            sticky.addClass('fixed');
        else
            sticky.removeClass('fixed');
    });

//    $('#activate-gift-card').click(function () {
//        $(".gift-card-action").toggle(this.checked);
//    });
//
//    $('#activate-for-gift').click(function () {
//        $(".mystery-box-order, .checkout").toggleClass('for-gift', this.checked);
//    });

    $('.countries h3').click(function (e) {
        $('.countries .countries-result').slideToggle(function () {
            $('.countries').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sort h3').click(function (e) {
        $('.sort .sort-result').slideToggle(function () {
            $('.sort').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sidebar h3, .toggle-filter').click(function (e) {
        $('.sidebar .widgets').slideToggle(function () {
            $('.sidebar').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.widget h4').click(function (e) {
        if ($(this).closest('.widget').hasClass('widget-active')) {
            $(this).closest('.widget').removeClass('widget-active');
        } else {
            $('.widget').removeClass('widget-active');
            $(this).closest('.widget').addClass('widget-active');
        }
        e.preventDefault();
    });

    $('.aside-dropdown .dd-menu > a').click(function (e) {
        $(this).siblings('ul').slideToggle(function () {
            $(this).parent().toggleClass('dd-menu-active');
        });
        e.preventDefault();
    });


    $('.toggle-filters').click(function (e){
        $('.widget-filters').toggleClass('d-none');
    });

    $('.toggle-sort').click(function (e){
        $('.widget-sorting ').toggleClass('d-none');
    });


    $('.read-more-toggle').click(function(e){
        $('.text-holder').toggleClass('stripped');

        if($('.text-holder').hasClass('stripped')){
            $('.read-more-toggle').text($('.read-more-toggle').data('on'));
        }else{
            $('.read-more-toggle').text($('.read-more-toggle').data('off'));
        }
    })

    $('.search-widget').click(function () {
        $('.search').toggleClass('open');
    });

    $('.nav-btn').click(function () {
        $(this).toggleClass('open');
        $('nav').toggleClass('open');
    });

    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     $('.dropdown-btn').click(function (){
    //         $(this).siblings('ul, .mega-menu').slideToggle();
    //         $(this).find('.fa').toggleClass('fa-angle-down fa-angle-up');
    //     });
    // } else {
    //     $('.menu > li').hover(function () {
    //         $(this).find('.mega-menu, .sub-menu').first().stop().slideDown(200);
    //     }, function () {
    //         $(this).find('.mega-menu, .sub-menu').stop().slideUp(200);
    //     });
    // }

    $('.owl-testimonials').owlCarousel({
        loop: true,
        margin: 0,
        responsiveClass: true,
        dots: true,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 1,
                nav: false
            },
            1000: {
                items: 1,
                nav: false
            },
            1600: {
                items: 1,
                nav: false
            }
        }
    });

    $('.owl-products').owlCarousel({
        loop: true,
        margin: 0,
        responsiveClass: true,
        dots: false,
        // stagePadding: 150,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: true,
                stagePadding: 80,
                margin: 0
            },
            635: {
                items: 2,
                nav: true,
                stagePadding: 80,
                margin: 0
            },
            1200: {
                items: 5,
                nav: true,
                // stagePadding: 80
            },
            1600: {
                items: 5,
                nav: true
            }
        }
    });

    $('.owl-special-products').owlCarousel({
        loop: true,
        margin: 0,
        responsiveClass: true,
        dots: false,
        // stagePadding: 150,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: true,
                stagePadding: 80,
                margin: 0
            },
            635: {
                items: 2,
                nav: true,
                stagePadding: 80,
                margin: 0
            },
            1200: {
                items: 3,
                nav: true,
                // stagePadding: 80
            },
            1600: {
                items: 4,
                nav: true
            }
        }
    });

    $('.owl-products-no-clone').owlCarousel({
        loop: false,
        rewind: true,
        margin: 0,
        responsiveClass: true,
        dots: false,
        // stagePadding: 150,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: true,
                stagePadding: 80,
                margin: 0
            },
            635: {
                items: 2,
                nav: true,
                stagePadding: 80,
                margin: 0
            },
            1200: {
                items: 3,
                nav: true,
                // stagePadding: 80
            },
            1600: {
                items: 4,
                nav: true
            }
        }
    });

    $(".scroll-reviews").click(function (e) {
        scrollPos = $('.comments').offset().top - 50;
        scrollTo(scrollPos, 2000);

        e.preventDefault();
    });

    const container = document.querySelector('.filter-list, .cart-result');
    if (container) {
        const ps = new PerfectScrollbar(container);

        $(".cart-result").hover(function () {
            ps.update();
        });

    }

    $('.menu .menu-item.rel .dropdown-btn').on('click', function (e) {
        let button = $(this);
        let parent = button.parent().parent();

        let subMenu = parent.find('.sub-menu')[0];
        $(subMenu).slideToggle();
        $(parent).toggleClass('menu-open');
        e.preventDefault();
    });


//    category menu
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $(".category-menu .menu .menu-item.compose").hover(function () {
            $(this).children(".sub-menu").toggleClass("show");
        });
    }

});


// scrollTop Y function
function scrollTo(position, speed) {

    speed = (typeof speed === "undefined") ? 500 : speed;

    $('html, body').animate({
        scrollTop: position
    }, speed);
}

