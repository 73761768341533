import Vue from 'vue/dist/vue.esm.js';

Vue.component('flashes', {
    template: '#flashes',
    props: {
        'flashes': {
            required: false,
            type: String,
            default: null
        }
    },
    data() {
        return {
            messages: []
        }
    },
    created() {
        let flashData = JSON.parse(this.flashes);

        for (let key in flashData) {
            let keyParts = key.split('_');

            if(keyParts[1] && keyParts[1]=='show'){
                flashData[key].forEach(message => this.messages.push({message: message, level: keyParts[0], didHide: false}));
            }else{
                flashData[key].forEach(message => this.messages.push({message: message, level: key, didHide: true}));
            }
        }

        window.EventManager.listen(
            'flash', messageData => this.addMessage(messageData)
        );
    },
    methods: {
        addMessage(messageData) {
            this.messages.push(messageData)
        }
    }
});