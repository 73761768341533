import { Controller } from "stimulus"

export default class extends Controller {
    initialize() {
        let subMenus = this.element.querySelectorAll('li');

        subMenus.forEach(item => {
            let route = item.dataset.route;

            if (this.data.get('currentRoute').includes(route)) {
                item.classList.add('bg-dark');
                item.querySelector('a').classList.add('text-white');
            }
        });
    }
}