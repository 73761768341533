import Vue from 'vue/dist/vue.esm.js';

Vue.component('limit-widget', {
    template: '#limit-widget',
    delimiters: ['[[', ']]'],
    props: {
        title: {
            required: true,
            type: String,
        },
        property: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
        },
        entries: {
            required: true,
            type: Array,
        },
    },

    mounted() {

        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get(this.property)) {
            //this.open = true;

            let _self = this;
            this.availableEntries.map(function (entry) {
                let propertyValue = searchParams.get(_self.property);
                if (entry.id == propertyValue) {
                    entry.selected = true;
                }
            });
        }
    },

    data() {
        return {
            availableEntries: this.entries,
            term: null,
            open: false,
        }
    },

    methods: {
        rebuildQuery() {
            let searchParams = new URLSearchParams(window.location.search);

            let selectedEntriesIds = this.availableEntries.filter(e => e.selected);

            let ids = [];
            for (let entry of selectedEntriesIds) {
                ids.push(entry.id);
            }

            if (ids.length) {
                searchParams.set(this.property, ids.join(','));
            } else {
                searchParams.delete(this.property);
            }

            searchParams.delete('page');

            history.replaceState(null, null, ' ');
            window.location.search = searchParams.toString();
        },

        selectEntry(entry) {

            this.availableEntries.map(function (entry) {
                entry.selected = false;
            })
            entry.selected = !entry.selected;
            this.rebuildQuery();
        },
    }
});