import Vue from 'vue/dist/vue.esm.js';

Vue.component('bracelet-configurator', {
    template: `#bracelet-configurator`,
    delimiters: ['[[', ']]'],
    props: {
        watchVariants: {
            required: true,
            type: Array,
        },
        bracelets: {
            required: true,
            type: Array,
        },
        materials: {
            required: true,
            type: Array,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        themes: {
            required: true,
            type: Array,
        },
        priceFilterRange: {
            required: true,
            type: Array,
        },
        sessionData: {
            required: true,
            type: String,
        },
        getElementsUrl: {
            required: true,
            type: String,
        }
    },
    mounted() {
        $('body').addClass('overflow-hidden');
        let __self = this;

        this.dragPseudoElement = document.getElementById('pseudo_drag_image');

        this.sessionData = JSON.parse(this.sessionData);

        if (this.bracelets.length > 0) {
            this.selectedBase = this.bracelets[0];
        }
        if (this.selectedBase && this.selectedBase.sizes.length > 0) {

            this.selectedSize = this.selectedBase.sizes[0];
            this.selectedBase.sizes.forEach(function (base){
                if(parseInt(base.size) === 17){
                    __self.selectedSize = base;
                }
            })
        }

        if (this.themes.length > 0) {
            this.selectedTheme = this.themes[0];
        }

        if (this.sessionData && this.sessionData.watchVariant) {
            this.selectedWatchVariant = JSON.parse(this.sessionData.watchVariant);
        }
        if (this.sessionData && this.sessionData.base && !this.selectedWatchVariant) {
            this.selectedBase = JSON.parse(this.sessionData.base);
        }

        if (this.sessionData && this.sessionData.size) {
            this.selectedSize = JSON.parse(this.sessionData.size);
        }

        if (this.priceFilterRange) {
            this.priceFilter.min = this.priceFilterRange[0].min;
            this.priceFilter.max = this.priceFilterRange[0].max;
        }

        if (this.sessionData && this.sessionData.elements) {
            this.sessionData.elements.forEach(function (el, key) {
                var tempPiece = __self.setPieceData(__self.defaultPiece, key + 1);

                if (el) {
                    el = JSON.parse(el);
                    tempPiece = __self.setPieceData(el, key + 1);
                }

                __self.result_pieces.push(tempPiece);
            })
            this.resetElementsVisibility();
            this.saveElementsData();
        } else {
            this.generateResult();
        }

        this.loadElements();
        this.calculateCart();
        EventManager.listen('open-configurator-total', this.openMobileCartTotal);

        setTimeout(function (){
            __self.themeSlider = $('.owl-carousel').owlCarousel({
                loop:false,
                nav:false,
                dots:false,
                responsive:{
                    0: {
                        items: 6
                    },
                    991: {
                        items: 2
                    },
                    1200: {
                        items: 4
                    },
                    1500: {
                        items: 6
                    },
                    1700: {
                        items: 8
                    }
                }
            });
        },1000)

    },
    data() {
        return {
            selectedWatchVariant: null,
            selectedBase: null,
            selectedMaterial: null,
            filterColorIsOpen: false,
            filterPriceIsOpen: false,
            filterMaterialIsOpen: false,
            filterSizeIsOpen: false,
            watchVariantsIsOpen: false,
            searchIsOpen: false,
            emptyResult: false,
            previewIsOpen: false,
            mobileCartTotal: false,
            modalIsOpen: false,
            selectedSize: null,
            selectedTheme: null,
            selectedElement: null,
            selectedBasePosition: null,
            elements: [],
            result_pieces: [],
            priceFilter: {'min':0,'max':1000},
            cartTotal: 0,
            searchKeyword: '',
            dragPseudoElement: null,
            tutorialOverlayShow: true,
            sentAjaxRequest : false
        }
    },
    methods: {
        togglePriceFilter(){
            let __self = this;

            this.filterPriceIsOpen = !this.filterPriceIsOpen;
            setTimeout(function (){
                __self.initPriceRangeSlider();
            }, 100)
        },
        loadElements(isTyping = false) {
            var keyword = this.searchKeyword;
            if (keyword.length > 0 && keyword.length < 3 && isTyping) {
                keyword = null;
            }

            this.elements = [];
            this.emptyResult = false;
            let _self = this;


            this.sentAjaxRequest = $.ajax({
                data: {
                    keyword: keyword,
                    theme: this.selectedTheme.id,
                    material: this.selectedMaterial?this.selectedMaterial.id:null,
                    price: this.priceFilter
                },
                beforeSend : function() {
                    if(_self.sentAjaxRequest && _self.sentAjaxRequest.readyState < 4) {
                        _self.sentAjaxRequest.abort();
                    }
                },
                url: this.getElementsUrl,
                method: 'POST',
                success: function (response) {
                    _self.elements = [];
                    var request_results = JSON.parse(response);
                    if (request_results.length > 0) {
                        _self.elements = request_results;
                    } else {
                        _self.emptyResult = true;
                    }
                    _self.sentAjaxRequest = false;
                },
                error: function (response) {
                    if(response.statusText !== 'abort'){
                        _self.loadElements();
                    }
                }
            });
        },
        generateResult() {
            for (var position = 1; position <= this.selectedSizeValue; position++) {
                var tempPiece = this.setPieceData(this.defaultPiece, position);
                this.result_pieces.push(tempPiece);
            }
            this.saveElementsData();
        },
        selectBase(base) {
            this.selectedBase = base;

            this.resetElementsVisibility();
            this.saveElementsData();
        },
        selectMaterial(material) {
            if(material == this.selectedMaterial){
                this.selectedMaterial = null;
            }else{
                this.selectedMaterial = material;
            }
            this.loadElements();
        },
        selectSize(size) {
            this.selectedSize = size;
            this.resetElementsVisibility();
            this.saveElementsData();
        },
        selectTheme(theme) {
            this.selectedTheme = theme;
            this.searchKeyword = '';
            this.loadElements();
        },
        selectElement(element) {
            this.selectedElement = element;
            if (this.isMobile) {
                if (this.selectedBasePosition) {
                    this.attachElementToBase(this.selectedElement, false, this.selectedBasePosition);
                    this.selectedBasePosition = null;
                    this.selectedElement = null;
                } else {
                    this.modalIsOpen = true;
                }
            }
        },
        selectWatchVariant(element){
            this.selectedWatchVariant = element;
            this.watchVariantsIsOpen = false;

            for (const baseElement of this.bracelets) {
                if (baseElement.hex_color === element.hex_color) {
                    this.selectBase(baseElement);
                    break;
                }
            }

            this.calculateCart()
        },
        selectBasePosition(position) {
            if (this.selectedBasePosition == position) {
                this.selectedBasePosition = null;
            } else {
                this.selectedBasePosition = position;
            }
            if (this.selectedElement && this.isMobile) {
                this.attachElementToBase(this.selectedElement, false, this.selectedBasePosition);
                this.selectedBasePosition = null;
                this.selectedElement = null;
            }
        },
        closeModal() {
            this.selectedElement = null;
            this.modalIsOpen = false;
        },
        dragElement(event,isElement=false,resultElement = null) {
            if(resultElement && resultElement.id>0){
                event.preventDefault();
                var targetElement = event.target;
                if (event.touches) {
                    var selectedElementWidth = (event.target.width);
                    var selectedElementHeight = $('#hiddenElement').height();
                    this.dragPseudoElement.src = event.target.src;
                    this.dragPseudoElement.style.width = selectedElementWidth + 'px';
                    this.dragPseudoElement.style.display = 'block';
                    this.dragPseudoElement.style.top = event.touches[0].clientY - (selectedElementHeight * 2) + 'px';
                    this.dragPseudoElement.style.left = event.touches[0].clientX - (selectedElementWidth / 2) + 'px';
                    var elementUnder = (document.elementFromPoint(event.touches[0].clientX, event.touches[0].clientY));
                } else {
                    var elementUnder = (document.elementFromPoint(event.clientX, event.clientY));
                }
                if (!elementUnder.classList.contains('disabled')) {
                    if (elementUnder && elementUnder != targetElement && !this.isMobile) {
                        if (elementUnder.classList.contains('result_image')) {
                            $('.result_image').removeClass('has_element_over');
                            elementUnder.classList.add('has_element_over');
                        } else {
                            $('.result_image').removeClass('has_element_over');
                        }
                    }
                    if(isElement){
                        event.target.classList.add('dragged');
                    }
                }
            }
        },
        dropElement(element, event) {
            event.target.classList.remove('dragged');
            if (event.changedTouches) {
                this.dragPseudoElement.style.display = 'none';
                var elementUnder = (document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY));
            } else {
                var elementUnder = (document.elementFromPoint(event.clientX, event.clientY));
            }
            if (elementUnder) {
                if (elementUnder.classList.contains('result_image')) {
                    this.attachElementToBase(element, elementUnder);
                    $('.result_image').removeClass('has_element_over');
                }
            }
        },
        removeElement(selectedPiece, event = false) {
            if(selectedPiece.id>0) {

                if (event) {
                    if (event.changedTouches) {
                        this.dragPseudoElement.style.display = 'none';
                        var elementUnder = (document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY));
                    } else {
                        var elementUnder = (document.elementFromPoint(event.clientX, event.clientY));
                    }
                }
                if (event && elementUnder.classList.contains('result_image')) {
                    let newPosition = parseInt(elementUnder.parentElement.getAttribute('position'));
                    if (selectedPiece.position != newPosition) {
                        this.moveElement(newPosition, selectedPiece);
                    }
                } else {
                    this.resetPiece(selectedPiece);
                }
                this.selectedBasePosition = null;
                this.resetElementsVisibility();
                this.saveElementsData();
            }
        },
        attachElementToBase(element, baseElement, position = false) {
            if (!position) {
                var elementStartPosition = parseInt(baseElement.parentElement.getAttribute('position'));
            } else {
                var elementStartPosition = position;
            }
            if(elementStartPosition === -1){
                return;
            }
            if ((elementStartPosition + element.takenPlaces - 1) > this.selectedSizeValue) {
                return;
            }
            this.result_pieces[elementStartPosition - 1].src = element.src;
            this.result_pieces[elementStartPosition - 1].id = element.id;
            this.result_pieces[elementStartPosition - 1].name = element.name;
            this.result_pieces[elementStartPosition - 1].takenPlaces = element.takenPlaces;
            this.result_pieces[elementStartPosition - 1].price = element.price;
            this.result_pieces[elementStartPosition - 1].position = elementStartPosition;
            this.result_pieces[elementStartPosition - 1].outOfStock = element.outOfStock;
            this.result_pieces[elementStartPosition - 1].hidden = false;

            this.resetElementsVisibility();
            this.saveElementsData();
        },
        moveElement(newPosition, selectedPiece) {
            if ((newPosition + selectedPiece.takenPlaces - 1) > this.selectedSizeValue) {
                return;
            }
            this.result_pieces[newPosition - 1].src = selectedPiece.src;
            this.result_pieces[newPosition - 1].id = selectedPiece.id;
            this.result_pieces[newPosition - 1].name = selectedPiece.name;
            this.result_pieces[newPosition - 1].takenPlaces = selectedPiece.takenPlaces;
            this.result_pieces[newPosition - 1].price = selectedPiece.price;
            this.result_pieces[newPosition - 1].position = newPosition;
            this.result_pieces[newPosition - 1].outOfStock = selectedPiece.outOfStock;
            this.result_pieces[newPosition - 1].hidden = false;
            this.resetPiece(selectedPiece);
            this.saveElementsData();
        },
        resetElementsVisibility() {
            let _self = this;
            this.selectedBase.sizes.map(function (baseSize) {
                if (baseSize.size == _self.selectedSizeValue) {
                    _self.selectedSize = baseSize;
                }
            });
            this.selectedSize = (_self.selectedSize);
            this.result_pieces.map(function (result_piece) {
                result_piece.hidden = false;
                if (result_piece.id === 0) {
                    result_piece.src = _self.selectedBase.src;
                }
            })
            if (_self.result_pieces.length < this.selectedSizeValue) {
                for (let i = _self.result_pieces.length; i < this.selectedSizeValue; i++) {
                    _self.result_pieces.push({
                        src: this.selectedBase.src,
                        id: 0,
                        name: "",
                        position: i + 1,
                        takenPlaces: 1,
                        price: 0,
                        hidden: false
                    });
                }
            } else if (_self.result_pieces.length > this.selectedSizeValue) {
                for (let i = _self.result_pieces.length; i > this.selectedSizeValue; i--) {
                    _self.resetPiece(_self.result_pieces[i - 1]);
                    _self.result_pieces.splice(i - 1, 1);
                }
                if (_self.result_pieces[_self.selectedSizeValue - 1].takenPlaces > 1) {
                    _self.resetPiece(_self.result_pieces[_self.selectedSizeValue - 1]);
                }
            }
            this.result_pieces.forEach(function (result_piece) {
                if (result_piece.takenPlaces > 1 && result_piece.position < _self.selectedSizeValue) {
                    for (var i = 1; i < result_piece.takenPlaces; i++) {
                        _self.resetPiece(_self.result_pieces[result_piece.position]);
                    }
                }
            })
            this.result_pieces.map(function (result_piece) {
                for (let nextPosition = 1; nextPosition < result_piece.takenPlaces; nextPosition++) {
                    _self.result_pieces[result_piece.position + nextPosition - 1].hidden = true;
                }
            })
            $('.result_image').removeClass('has_element_over');
        },
        resetPiece(piece) {
            piece.src = this.selectedBase.src;
            piece.id = 0;
            piece.name = "";
            piece.takenPlaces = 1;
            piece.price = 0;
            piece.hidden = false;
            piece.outOfStock = false;
        },
        setPieceData(data, position = null) {
            var piece = {};
            piece.src = data.src;
            piece.id = data.id;
            piece.name = data.name;
            piece.takenPlaces = data.takenPlaces;
            piece.price = data.price;
            piece.hidden = false;
            piece.outOfStock = false;
            piece.canRemove = data.canRemove;

            if (position) {
                piece.position = position;
            }
            return piece;
        },
        saveElementsData() {
            const configuratorType = this.sessionData.type || 'Bracelet'

            let configuratorData = {};
            configuratorData['base'] = this.selectedBase.id;
            configuratorData['size'] = this.selectedSize.id;
            configuratorData['elements'] = [];
            this.result_pieces.forEach(function (result_piece) {
                configuratorData['elements'].push(result_piece.id);
            });
            document.cookie = `configuratorData${configuratorType} = ` + (JSON.stringify(configuratorData) || "")  + "; path=/";
            this.calculateCart();
        },
        calculateCart() {
            let _self = this;
            _self.cartTotal = 0;

            if (this.selectedWatchVariant) {
                _self.cartTotal += parseFloat(this.selectedWatchVariant.price);
            }else{
                _self.cartTotal += parseFloat(this.freePositionsPrice);
            }

            this.result_pieces.map(function (result_piece) {
                _self.cartTotal += parseFloat(result_piece.price);
            });
            EventManager.fire('cart-calculate', this.formattedPrice(_self.cartTotal));
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        closeAllFilters() {
            this.filterSizeIsOpen = false;
            this.watchVariantsIsOpen = false;
            this.filterColorIsOpen = false;
            this.filterPriceIsOpen = false;
            this.filterMaterialIsOpen = false;
            this.modalIsOpen = false;
            this.mobileCartTotal = false;
            this.searchIsOpen = false;
            this.selectedElement = null;
            this.selectedBasePosition = null;
        },
        addElementToBracelet() {
            if (this.selectedElement && this.selectedBasePosition) {
                this.attachElementToBase(this.selectedElement, false, this.selectedBasePosition);
                this.selectedBasePosition = null;
                this.selectedElement = null;
                this.modalIsOpen = false;
            } else if (this.selectedElement) {
              //  if(!this.isMobile){
                    var firstFreePosition = this.getFirstFreePosition();
                    if(firstFreePosition){
                        this.attachElementToBase(this.selectedElement, false, firstFreePosition);
                    }
                    this.selectedElement = null;
                // }else{
                     this.modalIsOpen = false;
                // }
            }
        },
        openCloseBody(event) {
            var targetElement = $(event.target);
            if (!targetElement.hasClass('cart_summary_heading')) {
                targetElement = targetElement.parents('.cart_summary_heading');
            }
            targetElement.toggleClass('closed');
        },
        openMobileCartTotal() {
            this.selectedElement = null;
            this.modalIsOpen = false;
            this.filterColorIsOpen = false;
            this.filterPriceIsOpen = false;
            this.filterMaterialIsOpen = false;
            this.filterSizeIsOpen = false;
            this.watchVariantsIsOpen = false;
            this.searchIsOpen = false;
            this.mobileCartTotal = !this.mobileCartTotal;
        },
        togglePreview() {
            this.previewIsOpen = !this.previewIsOpen;
            $('body').toggleClass('preview');
        },
        getFirstFreePosition(){
            for(var i=0;i<this.result_pieces.length;i++){
                if (this.result_pieces[i].id === 0 && !this.result_pieces[i].hidden) {
                    return this.result_pieces[i].position;
                }
            }
            return false;
        },
        initPriceRangeSlider() {

            let slider = $(this.$refs.slider).data("ionRangeSlider");

            if (slider) {
                slider.destroy();
            }

            let _self = this;
            $(this.$refs.slider).ionRangeSlider({
                skin: "round",
                type: "double",
                min: _self.priceFilterRange[0].min,
                max: _self.priceFilterRange[0].max,
                hide_min_max: true,
                from: _self.priceFilter.min,
                to: _self.priceFilter.max,
                onChange: function (data) {
                    _self.priceFilter.min = data.from;
                    _self.priceFilter.max = data.to;
                }
            });
        },
        changeRangeMin() {
            let slider = $(this.$refs.slider).data("ionRangeSlider");
            slider.update({
                from: this.priceFilter.min,
            })
        },
        changeRangeMax() {
            let slider = $(this.$refs.slider).data("ionRangeSlider");
            slider.update({
                to: this.priceFilter.max,
            })
        },
        moveThemeSlider(direction){
            this.themeSlider.trigger(direction + '.owl.carousel');
        },
        canReduceBaseSize(){
            return !!this.selectedWatchVariant;
        }
    },
    computed: {
        selectedSizeValue() {
            return parseInt(this.selectedSize.size);
        },
        freeBasePlacesCounts() {
            return this.result_pieces.filter(e => e.id === 0 && !e.hidden).length;
        },
        freePositionsPrice() {
            let freeBasePlacesCounts = this.selectedSize.size;
            if (!this.canReduceBaseSize()) {
                freeBasePlacesCounts = this.freeBasePlacesCounts;
            }
            let singleElementPrice = parseFloat(this.selectedSize.price) / parseFloat(this.selectedSize.size);

            return parseFloat(singleElementPrice * freeBasePlacesCounts)
        },
        resultPiecesJson() {
            var finalResult = {};
            if (this.selectedWatchVariant) {
                finalResult['watchVariant'] = this.selectedWatchVariant.id;
            }

            finalResult['baseVariant'] = this.selectedSize.id;
            finalResult['totalPrice'] = this.cartTotal;
            finalResult['freeSpaces'] = this.freeBasePlacesCounts;
            finalResult['elements'] = [];
            this.result_pieces.forEach(function (result_piece) {
                finalResult['elements'].push(result_piece.id);
            });
            return JSON.stringify(finalResult);
        },
        isMobile() {
            return $(window).width() < 991;
        },
        cartIsInvalid() {
            if (this.freeBasePlacesCounts == this.selectedSizeValue) {
                return true;
            }
            for (var i = 0; i < this.result_pieces.length; i++) {
                if (this.result_pieces[i].outOfStock) {
                    return true;
                }
            }
            return false;
        },
        overlayIsOpen() {
            let overlayIsOpen = this.watchVariantsIsOpen ||  this.filterSizeIsOpen || this.filterColorIsOpen || this.filterPriceIsOpen || this.filterMaterialIsOpen || this.modalIsOpen || this.mobileCartTotal;
            $('body').toggleClass('modal-open', overlayIsOpen);
            return overlayIsOpen;
        },
        defaultPiece() {
            return {
                src: this.selectedBase.src,
                id: 0,
                name: "",
                takenPlaces: 1,
                hidden: false,
                price: 0
            }
        }
    }
});