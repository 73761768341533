import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ['image']

    connect() {
        this.urlAdd = this.element.dataset.urlAdd;
        this.urlRemove = this.element.dataset.urlRemove;
        this.selected = this.element.dataset.selected || 0;
        this.imageOn = this.element.dataset.imageOn;
        this.imageOff = this.element.dataset.imageOff;

        this.updateImage(this.selected);

    }

    updateImage(isSelected) {
        if (isSelected) {
            this.imageTarget.src = this.imageOn;
        } else {
            this.imageTarget.src = this.imageOff;
        }
    }

    change(event) {
        event.preventDefault();
        if (this.selected) {
            this.removeCompare();
        } else {
            this.addCompare();
        }
    }


    addCompare() {
        this.selected = 1;
        this.updateImage(true);
        this.buildAjax(this.urlAdd, 'add');
    }

    removeCompare() {
        this.selected = 0;
        this.updateImage(false);
        this.buildAjax(this.urlRemove, 'remove');
    }

    buildAjax(url, intention) {
        let _self = this
        $.ajax({
            data: null,
            url: url,
            method: 'POST',
            cache: false,
            contentType: false,
            processData: false,
            success: function (result) {
                _self.successAjax(_self, result, intention)
            },
        });
    }

    successAjax(_self, result, intention) {

        if (!result.success === true) {
            return;
        }

        if (intention === 'add') {
            EventManager.fire('compare-added');
        } else {
            EventManager.fire('compare-removed');
        }
    }
}
