import {Controller} from "stimulus"

import a2lix_lib from '../infrastructure/a2lix'

export default class extends Controller {
    connect() {
        let _self = this;

        let id = _self.element.getAttribute('id');

        if (!id) {
            console.error('The element needs to have an ID attribute for the a2lix controller to work.');
            return;
        }

        a2lix_lib.sfCollection.init({
            collectionsSelector: `#${id}`,
            manageRemoveEntry: true
        })
        a2lix_lib.sfCollection.handleColorAttribute({})

        var location = window.location;
        if (location.pathname.search('edit') !== -1) {

            if (!document.getElementById('attribute_isColor')) return;
            document.getElementById('attribute_isColor').disabled = 1;
        }
    }
}