export default class CustomUploadAdapter {
    constructor(loader, uploadUrl) {
        // CKEditor 5's FileLoader instance.
        this.loader = loader;
        this.xhr = null;
        this.uploadUrl = uploadUrl;
    }

    // Starts the upload process.
    upload() {
        let url = this.uploadUrl,
            self = this;

        return this.loader.file
            .then( uploadedFile => {
                return new Promise( ( resolve, reject ) => {
                    const data = new FormData();
                    data.append( 'upload', uploadedFile );

                    self.xhr = $.ajax({
                        url: url,
                        data,
                        contentType: false,
                        processData: false,
                        type: 'POST',
                        success: response => {
                            resolve({
                                default: response.url
                            });
                        },
                        error: (response) => {
                            let data = response.responseJSON;
                            reject(data && data.error ? data.error.message : 'Upload failed.');
                        }
                    });
                } );
            } );
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }
}