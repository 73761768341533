import Vue from 'vue/dist/vue.esm.js';

Vue.component('wishlist-widget', {
    template: '#wishlist-widget',
    delimiters: ['[[', ']]'],
    props: {
        initialCount: {
            required: true,
            type: Number,
        },
        mobileOnly: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    mounted() {

        EventManager.listen('favorite-added', this.favoriteAdded);
        EventManager.listen('favorite-removed', this.favoriteRemoved);

    },

    data() {
        return {
            count: this.initialCount,
        }
    },

    methods: {
        favoriteAdded() {
            this.count = this.count + 1;
        },
        favoriteRemoved() {
            this.count = this.count - 1;
        },

    }
});