import Vue from 'vue/dist/vue.esm.js';
import gmapsInit from '../utils/gmaps';
import MarkerClusterer from '@google/markerclusterer';

Vue.component('gmaps', {
    delimiters: ['[[', ']]'],
    template: '#gmaps',
    props: {
        address: {
            required: false,
            type: String,
            default: 'Romania'
        },
        zoom: {
            required: false,
            type: Number,
            default: 6
        },
        type: {
            required: false,
            type: String,
            default: null
        },
        offices: {
            required: false,
            type: Array,
            default: null
        },
        marker1: {
            required: true,
            type: String,
        },
        locationIcon: {
            required: true,
            type: String
        },
        marker2: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            isOfficeSelected: false,
            map: null,
            geocoder: null,
            selectedCity: this.address,
            selectedOffice: {
                name: null,
                city: null,
                telephone: null,
                workingTime: [],
                googleMapLink: null
            },
            styles: [
                {
                    url: this.marker1,
                    width: 40,
                    height: 40,
                    textColor: '#ffffff',
                    textSize: 10
                },
                {
                    url: this.marker2,
                    width: 40,
                    height: 40,
                    textColor: '#ffffff',
                    textSize: 11
                },
                {
                    url: this.marker2,
                    width: 40,
                    height: 40,
                    textColor: '#ffffff',
                    textSize: 12
                },
            ],
            mapStyles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#0c0b0b"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#090909"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#b3c0cb"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9b7f7f"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#fef7f7"
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        gmapType: function () {
            if (this.type === 'satellite') {
                return google.maps.MapTypeId.SATELLITE;
            } else if (this.type === 'hybrid') {
                return google.maps.MapTypeId.HYBRID;
            } else if (this.type === 'terrain') {
                return google.maps.MapTypeId.TERRAIN;
            }

            return google.maps.MapTypeId.ROADMAP;
        },

    },
    async mounted() {
        try {
            const google = await gmapsInit();
            const map = new google.maps.Map(this.$refs.gmap);
            this.geocoder = new google.maps.Geocoder();
            this.map = map;
            map.setOptions({styles: this.mapStyles});

            this.geocoder.geocode({address: this.address}, (results, status) => {
                if (status !== 'OK' || !results[0]) {
                    throw new Error(status);
                }
                map.setCenter(results[0].geometry.location);
                map.fitBounds(results[0].geometry.viewport);
                map.setZoom(this.zoom);
                map.mapTypeId = this.gmapType;
                map.gestureHandling = 'cooperative';
            });

            const markerClickHandler = (marker) => {
                map.setZoom(13);
                map.setCenter(marker.getPosition());
            };

            const markers = this.offices.map((location) => {
                const marker = new google.maps.Marker({...location, map,icon: this.locationIcon});
                marker.addListener('click', () => markerClickHandler(marker));

                return marker;
            });

            new MarkerClusterer(map, markers, {styles: this.styles,gridSize:20});

        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        filter() {

            this.geocoder.geocode({address: this.address + ',' + this.selectedCity}, (results, status) => {
                if (status !== 'OK' || !results[0]) {
                    throw new Error(status);
                }
                this.map.setCenter(results[0].geometry.location);
                this.map.fitBounds(results[0].geometry.viewport);
                this.map.setZoom(12);
                this.map.mapTypeId = this.gmapType;
                this.map.gestureHandling = 'cooperative';
            });
        },
        selectOffice(office) {
            this.selectedOffice.name = office.title;
            this.selectedOffice.telephone = office.telephone;
            this.selectedOffice.address = office.address;
            this.isOfficeSelected = true;
            this.selectedOffice.googleMapLink = "https://www.google.com/maps/dir//" + office.getPosition().lat() + "," + office.getPosition().lng();
        },
        deselectOffice() {
            this.isOfficeSelected = false;
        }
    }
});