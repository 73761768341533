import Vue from 'vue/dist/vue.esm.js';

Vue.component('compare-widget', {
    template: '#compare-widget',
    delimiters: ['[[', ']]'],
    props: {
        initialCount: {
            required: true,
            type: Number,
        },
        mobileOnly: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    mounted() {

        EventManager.listen('compare-added', this.compareAdded);
        EventManager.listen('compare-removed', this.compareRemoved);
    },

    data() {
        return {
            count: this.initialCount,
        }
    },

    methods: {
        compareAdded() {
            this.count = this.count + 1;
        },
        compareRemoved() {
            this.count = this.count - 1;
        },

    }
});