import Vue from 'vue/dist/vue.esm.js';
import * as MyPOSEmbedded from 'mypos-embedded-checkout';

Vue.component('mypos-embedded', {
    template: '#mypos-embedded',
    delimiters: ['[[', ']]'],
    props: {
        currency: {
            required: true,
            type: String,
        },
        locale: {
            required: true,
            type: String,
        },
        purchase: {
            required: true,
            type: Object,
        },
        updateDataUrl: {
            required: true,
            type: String,
        },
        captureUrl: {
            required: true,
            type: String,
        },
        sandbox: {
            required: true,
            type: Boolean,
        },
    },

    created() {
        this.isLoading = true;
    },

    mounted() {
        this.init();
        this.isLoading = false;
    },

    data() {
        return {
            isLoading: false,
            paymentParams: this.purchase,
            callbackParams: {
                isSandbox: this.sandbox,
                onSuccess: this.onSuccess,
                onError: this.onError
            }
        }
    },
    methods: {

        async postData(url = '', data = {}) {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'

                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
            return response.json();
        },

        init() {

            this.paymentParams.ipcLanguage = this.locale;
            let _self = this;

            MyPOSEmbedded.createPayment(
                'myPOSEmbeddedCheckout',
                _self.paymentParams,
                _self.callbackParams
            );
        },
        onSuccess(data) {
            let _self = this;
            _self.postData(_self.captureUrl, data).then(function (response) {

                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
                _self.isLoading = false;
            });
        },
        onError(data) {
            console.log('error', data);
        },

    }
});