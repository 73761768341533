import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['loadMoreButton'];

    initialize() {
        this.offset = 0;
        this.limit = parseInt(this.data.get('limit'));
        this.count = parseInt(this.data.get('count'));
    }

    loadMore() {

        this.offset += this.limit;

        this.load();
    }

    load() {
        let url = this.data.get('url');
        let searchParams = new URLSearchParams();
        searchParams.append('offset', this.offset);
        searchParams.append('limit', this.limit);
        url += '?' + searchParams.toString();
        fetch(url)
            .then(response => response.text())
            .then(html => {
                $(html).insertBefore($(this.loadMoreButtonTarget));
            }).then(t => {
            if (this.offset + this.limit >= this.count) {
                $(this.loadMoreButtonTarget).hide();
            }
        });
    }
}