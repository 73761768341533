import {Controller} from "stimulus"


export default class extends Controller {

    connect() {

        this.eventData = this.element.getAttribute('data-data');
        this.eventData = JSON.parse(this.eventData);

        this.facebookPurchase();
        this.gtagPurchase();
    }

    facebookPurchase() {
        let fbData = this.prepareFacebookPurchaseData();

        fbq('track', 'Purchase',
            {
                value: fbData.total,
                currency: 'EUR',
                contents: fbData.products,
                content_type: 'product',
            }
        );

    }

    gtagPurchase(){

        let gtagData = this.prepareGtagPurchaseData();

        gtag('event', 'purchase', {
            "transaction_id": gtagData.id,
            "affiliation": "WebStore",
            "value": gtagData.total,
            "currency": "EUR",
            "items": gtagData.products
        });

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'transactionId': gtagData.id,
            'transactionTotal': gtagData.total,
            'transactionProducts': gtagData.products
        });

    }

    prepareFacebookPurchaseData() {

        let data = {};
        data.products = [];
        data.total = this.eventData.total

        this.eventData.products.forEach(row => {
            let product = {};
            product.id = row.id;
            product.quantity = row.quantity;
            product.item_price = row.priceInEUR;
            data.products.push(product);
        })

        return data;
    }
    prepareGtagPurchaseData() {

        let data = {};
        data.products = [];
        data.total = this.eventData.total
        data.id = this.eventData.id

        this.eventData.products.forEach(row => {
            let product = {};
            product.sku = row.sku;
            product.id = row.id;
            product.price = row.priceInEUR;
            product.quantity = row.quantity;
            product.name = row.name;
            data.products.push(product);
        })

        return data;
    }
}