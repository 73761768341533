'use strict';

const a2lix_lib = {};

a2lix_lib.sfCollection = (() => {
    const init = (config = {}) => {
        if (!('content' in document.createElement('template'))) {
            console.error('HTML template will not work...');
            return
        }

        const {
            collectionsSelector = 'form div[data-prototype]',
            manageRemoveEntry = true
        } = config;

        const collectionsElt = document.querySelectorAll(collectionsSelector);

        if (!collectionsElt.length) {
            return
        }

        collectionsElt.forEach(collectionElt => {
            processCollectionElt(collectionElt, manageRemoveEntry)
        })
    };

    const processCollectionElt = (collectionElt, manageRemoveEntry = false) => {
        collectionElt.setAttribute(
            'data-entry-index',
            collectionElt.children.length
        );

        appendEntryAddLink(collectionElt);

        if (manageRemoveEntry) {
            appendEntryRemoveLink(collectionElt)
        }

        collectionElt.addEventListener('click', evt =>
            configureCollectionElt(evt, manageRemoveEntry)
        )
    };

    const appendEntryAddLink = collectionElt => {
        // Allow custom label
        const entryLabel = collectionElt.getAttribute('data-entry-label') || '';

        const addLinkIcon = document.createElement('i');
        addLinkIcon.className = "fa fa-plus no-pointers";
        const entryAddLink = getButtonElt(
            ` Добави ${entryLabel}`,
            'add',
            'btn btn-outline-secondary mb-3 ml-3'
        );
        entryAddLink.prepend(addLinkIcon);
        collectionElt.appendChild(entryAddLink)
    };

    const appendEntryRemoveLink = collectionElt => {
        const entryRemoveLink = getButtonElt(
            '\u2716',
            'remove',
            'btn btn-outline-info btn-sm'
        );
        /* const removeIcon = document.createElement('i');
         removeIcon.className = 'fa fa-remove no-pointers';
         entryRemoveLink.prepend(removeIcon);*/
        const tdTag = document.createElement('div');
        tdTag.appendChild(entryRemoveLink.cloneNode(true));

        const collectionChildren = [...collectionElt.children]
            .filter(entryElt => !entryElt.hasAttribute('data-entry-action'))
            .forEach(entryElt => {

                entryElt.querySelector('.remove-holder').appendChild(tdTag.cloneNode(true))
            })
    };

    const configureCollectionElt = (evt, manageRemoveEntry) => {

        if (!evt.target.hasAttribute('data-entry-action')) {
            return
        }

        switch (evt.target.getAttribute('data-entry-action')) {
            case 'add':
                addEntry(evt.currentTarget, evt.target, manageRemoveEntry);
                break;
            case 'remove':
                removeEntry(evt.currentTarget, evt.target);
                break
        }
    };

    const addEntry = (collectionElt, entryAddButton, manageRemoveEntry) => {
        // Get & update entryIndex
        const entryIndex = collectionElt.getAttribute('data-entry-index');
        collectionElt.setAttribute('data-entry-index', +entryIndex + 1);

        const entryPrototype = collectionElt.getAttribute('data-prototype'),
            templateContent = getTemplateContent(entryPrototype, entryIndex);


        // Add remove button, if necessary, before insert to the DOM
        if (manageRemoveEntry) {
            const entryRemoveLink = getButtonElt(
                "\u2716",
                'remove',
                'btn btn-outline-info btn-sm'
            );

            /* const removeIcon = document.createElement('i');
             removeIcon.className = 'fa fa-remove no-pointers';
             entryRemoveLink.prepend(removeIcon);*/
            const tdTag = document.createElement('div');
            tdTag.appendChild(entryRemoveLink);
            templateContent.querySelector('.remove-holder').appendChild(tdTag);
        }

        entryAddButton.parentElement.insertBefore(templateContent, entryAddButton);

        var allEntities = $(collectionElt).find('tr').last().find('.select2entity');
        allEntities.each(function (index, elem) {
            $(elem).select2entity();
        });

        handleColorAttribute();

    };

    const handleColorAttribute = () => {
        if (!document.getElementById('attribute_isColor')) return;
        if (document.getElementById('attribute_isColor').checked) {
            var labelsCopy = [];
            var element = document.getElementById('options-wrapper').getElementsByClassName('attribute-value');

            if (element !== null) {
                if (document.getElementsByClassName('color-label').length >= 1) {
                    var labels = document.getElementsByClassName('color-label');
                    labels.forEach((element, index) => {
                        labelsCopy[index] = element.value;
                    });

                    while (labels.length > 0) {
                        labels[0].parentNode.removeChild(labels[0]);
                    }
                }

                element.forEach((child2, index) => {
                    var location = window.location.pathname;
                    if (child2 !== null) {

                        //console.log(labelsCopy[index]);

                        console.log(child2.value);

                        let numberOfColors = child2.value.split(",");

                        if (numberOfColors.length === 1) {
                            child2.type = 'color';
                        }

                        if (location.search('create') !== -1) {
                            var input = document.createElement("input");
                            input.type = "text";
                            input.placeholder = 'Заглавие';
                            input.name = "labels[] ";
                            input.className = 'color-label'
                            document.getElementById('options-wrapper').insertBefore(input, element[index].firstChild);
                        }
                    }
                });
                if (labels) {
                    labels.forEach((element, index) => {
                        if (labelsCopy[index]) {
                            labels[index].value = labelsCopy[index];
                        }
                    });
                }
            }
        }
    };

    const removeEntry = (collectionElt, entryRemoveButton) => {
        const entryIndex = collectionElt.getAttribute('data-entry-index');
        collectionElt.setAttribute('data-entry-index', entryIndex - 1);

        entryRemoveButton.closest('.proto').remove();

        updateIndexes(collectionElt);
    };

    /**
     * HELPERS
     */

    const getButtonElt = (label, action, className = 'btn') => {
        const button = document.createElement('button');

        button.type = 'button';
        button.textContent = label;
        button.className = className;
        button.dataset.entryAction = action;

        return button
    };

    const getTemplateContent = (entryPrototype, entryIndex) => {
        const template = document.createElement('template');

        const entryHtml = entryPrototype
            .replace(/__name__label__/g, `!New! ${entryIndex}`)
            .replace(/__name__/g, entryIndex);

        template.innerHTML = entryHtml.trim();

        return template.content
    };

    const updateIndexes = (collectionElement) => {
        collectionElement.children.forEach((child, index) => {

            replaceIndexesRecursive(child, index);
        });
    };

    const replaceIndexesRecursive = (element, index) => {
        for (let attribute of element.attributes) {
            let attributeValue = attribute.value
                .replace(/\[[\d]+\]/g, `[${index}]`)
                .replace(/_[\d]+_/g, `_${index}_`);

            if (attributeValue != attribute.value) {
                element.setAttribute(attribute.name, attributeValue);
            }
        }

        for (let i = 0; i < element.childElementCount; i++) {
            let childElement = element.children[i];
            replaceIndexesRecursive(childElement, index);
        }
    };

    return {
        init, handleColorAttribute
    }
})();

export default a2lix_lib;
