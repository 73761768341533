import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        Sortable.create(this.element, {
            ghostClass: 'blue-background-during-drag',
            animation: 300
        });
    }
}