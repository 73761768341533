import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ['image']

    connect() {
        this.url = this.element.dataset.url;
        this.id = this.element.dataset.id;
        this.action = this.element.dataset.context;
    }

    addToCart(event) {
        event.preventDefault();
        this.buildAjax(this.url, this.id);
    }

    buildAjax(url, id) {
        let _self = this
        $.ajax({
            data: {id: id},
            url: url,
            method: 'POST',
            success: function (result) {
                _self.successAjax(result)
            },
        });
    }

    successAjax(result) {
        let modal = $('#checkout-modal');
        modal.modal('show');

        let successMessage = $('#success');
        let failMessage = $('#fail');

        if (result.success === false) {
            successMessage.hide();
            failMessage.text(result.message);
            failMessage.addClass('cart-delivery-hint');
            failMessage.show();

            return;

        } else {
            successMessage.show();
            failMessage.hide();
        }

        EventManager.fire('item-added', result.cart);

        let event = {
            event: 'eec.add',
            eventData: result.variant,
            action: this.action,
        }


        fbq('track', 'AddToCart', {
            content_ids: [result.variant.id],
            content_type: 'product',
            value: result.variant.priceInEUR,
            currency: 'EUR'
        });

        gtag('event', 'add_to_cart', {
            "items": [
                {
                    "id": result.variant.id,
                    "name": result.variant.name,
                    "list_name": "Product list",
                    "quantity": 1,
                    "price": result.variant.priceInEUR
                }
            ]
        });

        EventManager.fire('gtm-event', event);
    }
}
