import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ['field', 'toggle', 'icon'];

    initialize() {
        //
    }

    connect() {

        let field = this.fieldTarget;
        let toggle = this.toggleTarget;

        console.log(field);

        toggle.addEventListener('click', (event) => {
            let type = field.type;
            if (type === 'password') {
                type = 'text';
            } else {
                type = 'password';
            }
            field.type = type;
            this.iconTarget.classList.toggle('fa-eye-slash');
            this.iconTarget.classList.toggle('fa-eye');

        });
    }

}