import Vue from 'vue/dist/vue.esm.js';

Vue.component('product-gallery', {
    template: `#product-gallery`,
    delimiters: ['[[', ']]'],
    props: {
        product: {
            required: true,
            type: Object,
        },
    },

    mounted() {
        this.slider = '#sync1';
        this.thumbSlider = "#sync2";
        this.thumbnailItemClass = '.owl-item';
        this.initSlides();
        EventManager.listen('gallery-changed', this.galleryChanged);
    },

    data() {
        return {
            gallery: this.product.gallery,
            reinit: false,
            slider: null,
            thumbSlider: null,
            thumbnailItemClass: null,
        }
    },

    methods: {
        initSlides() {
            this.reinit = false;
            let _self = this;
            this.$nextTick(() => {
                this.reinit = true;

                this.$nextTick(() => {

                    $(this.slider).owlCarousel({
                        video: true,
                        startPosition: 0,
                        items: 1,
                        loop: true,
                        margin: 10,
                        autoplay: false,
                        autoplayTimeout: 6000,
                        autoplayHoverPause: false,
                        nav: false,
                        dots: false
                    }).on('changed.owl.carousel', this.syncPosition);

                    $(this.thumbSlider).owlCarousel({
                        startPosition: 0,
                        items: 4,
                        loop: false,
                        margin: 10,
                        autoplay: false,
                        nav: true,
                        dots: false,
                        onInitialized: function (e) {
                            let thumbnailCurrentItem = $(e.target).find(_self.thumbnailItemClass).eq(this._current);
                            thumbnailCurrentItem.addClass('synced');
                        },
                    }).on('click', _self.thumbnailItemClass, function (e) {
                        e.preventDefault();
                        let duration = 300;
                        let itemIndex = $(e.target).parents(_self.thumbnailItemClass).index();
                        $(_self.slider).trigger('to.owl.carousel', [itemIndex, duration, true]);

                    });
                });

            });

        },

        galleryChanged(data) {
            this.gallery = data;
            this.initSlides();
        },
        syncPosition(el) {
            let _self = this;
            let owl_slider = $(_self.slider).data('owl.carousel');
            let loop = owl_slider.options.loop;

            let current;
            if (loop) {
                let count = el.item.count - 1;
                current = Math.round(el.item.index - (el.item.count / 2) - .5);
                if (current < 0) {
                    current = count;
                }
                if (current > count) {
                    current = 0;
                }
            } else {
                current = el.item.index;
            }

            let owl_thumbnail = $(_self.thumbSlider).data('owl.carousel');
            let itemClass = "." + owl_thumbnail.options.itemClass;

            let thumbnailCurrentItem = $(_self.thumbSlider)
                .find(itemClass)
                .removeClass("synced")
                .eq(current);

            thumbnailCurrentItem.addClass('synced');

            if (!thumbnailCurrentItem.hasClass('active')) {
                let duration = 300;
                $(_self.thumbSlider).trigger('to.owl.carousel', [current, duration, true]);
            }
        }
    },

    computed: {}
});