import Vue from 'vue/dist/vue.esm.js';

Vue.component('tabs', {
    template: '#tabs',
    delimiters: ['[[', ']]'],
    data() {
        return { tabs: [] };
    },

    created() {
        this.tabs = this.$children;
    },

    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.href === selectedTab.href);
            });
        }
    }
});