import Vue from 'vue/dist/vue.esm.js';

Vue.component('header-configurator-cart', {
    template: `#header-configurator-cart`,
    delimiters: ['[[', ']]'],
    props: {},
    mounted() {
        let _self = this;
        EventManager.listen('cart-calculate', function (cartTotal){
            _self.cartTotal = cartTotal;
        });
    },

    data() {
        return {
            cartTotal: ''
        }
    },

    methods: {
        toggleConfiguratorTotal(){
            EventManager.fire('open-configurator-total');
        }
    }
});