import Vue from 'vue/dist/vue.esm.js';

Vue.component('tab', {
    template: '#tab',
    props: {
        name: {required: true},
        selected: {default: false}
    },

    data() {
        return {
            isActive: false
        };
    },

    computed: {
        href() {
            return '#' + this.name.toLowerCase().replace(/ /g, '-');
        }
    },

    mounted() {
        let currentUrl = window.location.href,
            parts = currentUrl.split('#'),
            fragment = null;

        if (parts.length > 1) {
            fragment = decodeURI(parts[parts.length - 1]).toLowerCase().replace(/ /g, '-');
            this.isActive = ('#' + fragment) === this.href;
        } else {
            this.isActive = this.selected;
        }
    },
});