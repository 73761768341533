import Vue from 'vue/dist/vue.esm.js';

Vue.component('checkout', {
    template: `#checkout`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        delivery: {
            required: true,
            type: Array,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeRowUrl: {
            required: true,
            type: String,
        },
        incrementUrl: {
            required: true,
            type: String,
        },
        decrementUrl: {
            required: true,
            type: String,
        },
        promocodeUrl: {
            required: true,
            type: String,
        },
        removePromocodeUrl: {
            required: true,
            type: String,
        },
        fetchOfficesUrl: {
            required: true,
            type: String,
        },
        freeDeliveryOver: {
            required: true,
            type: Number,
        },
        hasUser: {
            required: true,
            type: Number,
        },
        userAddresses: {
            required: false,
            type: Array,
        },
        minimalOrderValue: {
            required: true,
            type: Number,
        }
    },

    mounted() {


        this.initSaveAddress();
        this.initAllowNewAddress();

        if (parseInt(this.$refs.invoice.dataset.val)) {
            this.invoice = parseInt(this.$refs.invoice.dataset.val);
            if(this.invoice){
                this.isInvoiceToggled = 1;
            }
        }

        if (parseInt(this.$refs.createProfile.dataset.val)) {
            this.createProfile = parseInt(this.$refs.createProfile.dataset.val);
            if(this.createProfile){
                this.createUserToggled = 1;
            }
        }

        if (parseInt(this.$refs.selectedType.dataset.val)) {
            this.selectType(this.delivery.filter(d => d.id === parseInt(this.$refs.selectedType.dataset.val))[0]);
        }
        if (parseInt(this.$refs.existingAddress.dataset.val)) {
            this.selectUserAddress(this.userAddresses.filter(d => d.id === parseInt(this.$refs.existingAddress.dataset.val))[0]);
        }

        if (parseInt(this.$refs.selectedArea.dataset.val)) {
            this.selectArea(this.selectedType.areas.filter(d => d.id === parseInt(this.$refs.selectedArea.dataset.val))[0], false);
        }

        if (parseInt(this.$refs.selectedOption.dataset.val)) {
            this.selectOption(this.selectedArea.options.filter(d => d.id === parseInt(this.$refs.selectedOption.dataset.val))[0]);
        }

        if (parseInt(this.$refs.selectedPaymentMethod.dataset.val)) {
            this.selectPaymentMethod(this.selectedOption.paymentMethods.filter(d => d.id === parseInt(this.$refs.selectedPaymentMethod.dataset.val))[0]);
        }

        if (this.$refs.cityField && this.$refs.cityField.value) {
            this.selectedCity = this.$refs.cityField.value;
        }


        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('order-address:city', this.cityChanged);
        EventManager.listen('item-removed', this.cartUpdated);
    },


    data() {
        return {
            types: this.delivery,
            cart: this.initialCart,
            showPromocode: false,
            hasPromocode: !!this.initialCart.promocode,
            promocode: this.initialCart.promocode,
            promocodeError: null,
            selectedType: null,
            selectedArea: null,
            selectedOption: null,
            selectedSlot: null,
            selectedCity: null,
            selectedUserAddress: null,
            selectedPaymentMethod: null,
            allowNewAddress: 0,
            deliveryPrice: null,
            saveAddress: 0,
            isSlotSelecting: false,
            quantityAlert: {
                visible: false,
                productName: '',
            },
            noOfficesWarning: false,
            isLoginToggled: false,
            invoice: 0,
            createProfile: 0,
            isInvoiceToggled: false,
            createUserToggled: false,
        }
    },

    methods: {

        initSaveAddress() {
            let value;
            if (this.validateRef("saveAddress")) {
                if (this.$refs.saveAddress.dataset.val === '') {
                    return;
                }
                if (isNaN(parseInt(this.$refs.saveAddress.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.saveAddress.dataset.val);
                }
            }
            this.saveAddress = value;
        },
        initAllowNewAddress() {
            let value = this.allowNewAddress;
            if (this.validateRef("allowNewAddress")) {
                if (this.$refs.allowNewAddress.dataset.val === '') {
                    return;
                }
                if (isNaN(parseInt(this.$refs.allowNewAddress.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.allowNewAddress.dataset.val);
                }
            }
            this.allowNewAddress = value;
        },

        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },

        selectType(type) {

            if (this.selectedType === type) {
                return;
            }

            this.selectedType = type;

            this.noOfficesWarning = false;
            this.selectedArea = null;
            this.selectedOption = null;
            this.selectedCity = null;
            this.deliveryPrice = null;
            this.selectedPaymentMethod = null;

            if (this.selectedType.areas.length === 1) {
                this.selectArea(this.selectedType.areas[0])
                //this.selectedArea = this.selectedType.areas[0];
            }
        },
        selectArea(area, refreshCity = false) {

            if (this.selectedArea === area) {
                return;
            }
            this.selectedArea = area;
            this.selectedOption = null;
            this.deliveryPrice = null;

            this.noOfficesWarning = false;
            this.selectedPaymentMethod = null;

            let select2 = $(this.$refs.cityField);

            if (refreshCity) {
                select2.val(null).trigger('change');
            }
            if (area.city.id) {
                this.selectedCity = area.city.id;
                select2.attr('readonly', 'readonly');
                this.preselectCity(area.city);
            } else {
                select2.removeAttr('readonly');
            }
        },

        selectOption(option) {
            if (this.selectedOption === option) {
                return;
            }
            this.selectedOption = option;
            this.deliveryPrice = option.price;

            if (this.$refs.cityField && this.$refs.cityField.value) {
                this.selectedCity = this.$refs.cityField.value;
            }

            if (this.hasUser) {
                this.saveAddress = 1;
            }

            if (this.selectedType.isDigital) {
                this.saveAddress = 0;
            }

            this.tryToPopulateOffices(true);
        },

        selectUserAddress(address) {
            if (this.selectedUserAddress === address) {
                return;
            }
            this.selectedUserAddress = address;

            this.saveAddress = 0;

            this.selectedType = address.type;
            this.selectedArea = address.area;
            this.selectedOption = address.deliveryOption;
            this.deliveryPrice = address.deliveryOption.price;

        },
        removeOptions(selectElement, clearSelected = false) {
            if (!selectElement) {
                return;
            }

            let i, Length = selectElement.options.length - 1;
            for (i = Length; i >= 1; i--) {

                if (selectElement.options[i].selected == true && !clearSelected) {
                    continue;
                }

                selectElement.remove(i);

            }
        },


        tryToPopulateOffices(clearSelected = false) {

            if (this.selectedCity && this.selectedOption && this.selectedType.isOffice) {
                let officeSelect = $(this.$refs.officeField);
                let citySelect = $(this.$refs.cityField);
                this.noOfficesWarning = false;
                this.postData(this.fetchOfficesUrl, {courierId: this.selectedOption.courier.id, cityId: this.selectedCity})
                    .then(data => {
                        this.removeOptions(this.$refs.officeField, clearSelected);
                        if (!data.results) {
                            this.noOfficesWarning = true;
                            this.selectedCity = null;
                            citySelect.val(null).trigger('change');

                            return;

                        }
                        for (let each of data.results) {
                            let newOption = new Option(each.text, each.id, false, false);
                            officeSelect.append(newOption);
                        }
                    });

            }
        },

        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },

        selectPaymentMethod(paymentMethod) {
            this.selectedPaymentMethod = paymentMethod;
        },
        selectSlot(slot) {
            this.selectedSlot = slot;
        },
        cartUpdated(data) {
            this.cart = JSON.parse(data);
            if (this.cart.rows.length === 0) {
                window.location.href = '/cart';
            }
        },
        cityChanged(data) {
            this.selectedCity = data;
            this.tryToPopulateOffices(true);
        },

        toggleLogin() {
            this.isLoginToggled = !this.isLoginToggled & 1;
        },

        toggleInvoice() {
            this.isInvoiceToggled = !this.isInvoiceToggled & 1;
            if (this.isInvoiceToggled) {
                this.invoice = 1;
            } else {
                this.invoice = 0;
            }
        },
        toggleCreateUser() {
            this.createUserToggled = !this.createUserToggled & 1;
            if (this.createUserToggled) {
                this.createProfile = 1;
            } else {
                this.createProfile = 0;
            }
        },

        toggleSlotSelection() {
            this.isSlotSelecting = !this.isSlotSelecting;
            if (!this.isSlotSelecting) {
                this.selectedSlot = null;
            }

        },
        toggleSaveAddress() {
            this.saveAddress = !this.saveAddress & 1;
        },

        toggleNewAddressSelection() {
            this.allowNewAddress = !this.allowNewAddress & 1;
            this.selectedUserAddress = null;
            this.selectedOption = null;
            this.selectedType = null;
            this.selectedArea = null;
        },


        preselectCity(city) {

            let select2 = $(this.$refs.cityField);
            let newOption = new Option(city.name, city.id, true, true);

            select2.append(newOption).trigger('change');
        },

        removeRow(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.removeRowUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('item-removed', result.cart);
                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CheckoutPage',
                    }
                    EventManager.fire('gtm-event', event);
                },
            });
        },
        addVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.variantId, engraving: row.hasEngraving},
                url: _self.incrementUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success !== false) {
                        _self.quantityAlert.visible = false;
                        EventManager.fire('item-added', result.cart);

                        let event = {
                            event: 'eec.add',
                            eventData: result.variant,
                            action: 'CheckoutPage',
                        }
                        EventManager.fire('gtm-event', event);
                    } else {
                        _self.quantityAlert.visible = true;
                        _self.quantityAlert.productName = row.name;
                    }
                },
            });
        },
        decrementVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.decrementUrl,
                method: 'POST',
                success: function (result) {
                    _self.quantityAlert.visible = false;
                    EventManager.fire('item-removed', result.cart);

                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CheckoutPage',
                    }
                    EventManager.fire('gtm-event', event);
                },
            });
        },

        incrementRowQuantity(row) {
            if (row.quantity > 99) {
                return;
            }
            this.addVariantQuantity(row);

        },

        decrementQuantity(row) {
            if (row.quantity === 1) {
                return;
            }
            this.decrementVariantQuantity(row);
        },
        formattedRowPrice(row) {
            return this.formattedPrice(row.quantity * row.price);
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        togglePromocodeField() {
            this.showPromocode = !this.showPromocode;
        },
        applyPromocode() {
            let _self = this;
            $.ajax({
                data: {code: _self.promocode},
                url: _self.promocodeUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success === false) {
                        _self.promocodeError = result.error;
                    } else {
                        _self.promocodeError = null;
                        _self.hasPromocode = true;
                        EventManager.fire('item-added', result);
                    }
                },
            });
        },

        removePromocode() {
            let _self = this;
            $.ajax({
                url: _self.removePromocodeUrl,
                method: 'POST',
                success: function (result) {
                    _self.hasPromocode = false;
                    EventManager.fire('item-added', result);
                },
            });
        },

        qualifiesForFreeDelivery() {

            if (this.selectedOption && !this.selectedOption.allowFreeShipping) {
                return false;
            }
            return this.cart.total >= this.freeDeliveryOver;
        },

        calcDeliveryPrice(price) {

            if (this.cart.total >= this.freeDeliveryOver) {
                return this.formattedPrice(0);
            } else {
                return this.formattedPrice(price);
            }
        },
        calcOptionDeliveryPrice(option) {

            if (option === this.selectedOption && !option.allowFreeShipping) {
                return this.formattedPrice(option.price);
            }

            if (!option.allowFreeShipping && this.selectedOption !== option) {
                return this.formattedPrice(option.price);
            }

            if (this.cart.total >= this.freeDeliveryOver) {
                return this.formattedPrice(0);
            } else {
                return this.formattedPrice(option.price);
            }
        },
        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index, attribute] of attributes.entries()) {
                if (index !== 0) {
                    str += ', '
                }
                if (attribute.showUnit) {
                    str += attribute.unit + ' x '
                }
                str += ' ' + attribute.value;

            }
            return str;
        },

        getAvailableMethods() {
            let methods = this.selectedOption.paymentMethods;

            if (this.hasEngravings()) {
                methods = methods.filter(pm => pm.isAvailableForGift === true);
            }

            if(this.hasGiftCard()){
                methods = methods.filter(pm => pm.method == 'credit_card');
            }

            if (methods.length === 1) {
                this.selectPaymentMethod(methods[0]);

            }
            return methods;
        },
        hasEngravings(){
            let cart = this.cart;
            for (let i = 0; i < cart.rows.length; i++) {
                if (cart.rows[i].hasEngraving === true) {
                    return true;
                }
            }
            return false;
        },
        hasGiftCard(){
            let cart = this.cart;
            for (let i = 0; i < cart.rows.length; i++) {
                if (cart.rows[i].isGiftCard === true) {
                    return true;
                }
            }
            return false;
        },
        formattedDate(datetimeString) {

            let date = new Date(datetimeString);
            return new Intl.DateTimeFormat(this.locale, {day: 'numeric', month: 'numeric', weekday: 'long'}).format(date);
        },
        formattedSlotTime(slot) {
            let startDate = new Date(slot.startTime.date);
            let endDate = new Date(slot.endTime.date);

            startDate = Intl.DateTimeFormat(this.locale, {timeStyle: 'short'}).format(startDate);
            endDate = Intl.DateTimeFormat(this.locale, {timeStyle: 'short'}).format(endDate);
            return startDate + ' - ' + endDate;
        },
        calcTotal(includeDelivery) {

            let total = this.cart.total;
            if (includeDelivery) {
                total += this.deliveryPrice ?? 0;
            }
            return total;
        },

        shouldShowAddressFields() {

            if (this.selectedType && (this.selectedType.isPhysical === true || this.selectedType.isDigital)) {
                return true;
            }
            return false;
        }

    },

    computed: {
        formattedTotal() {
            if (this.qualifiesForFreeDelivery()) {
                return this.formattedPrice(this.calcTotal(false))
            }

            return this.formattedPrice(this.calcTotal(true))

        },

        computedDeliveryPrice() {
            if (this.qualifiesForFreeDelivery()) {
                return 0;
            }
            return this.deliveryPrice;
        },
        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal - (this.cart.originalTotal / 6));
        },
        formattedVAT() {
            return this.formattedPrice(this.cart.originalTotal / 6);
        },
        amountToFreeShipping() {
            return this.formattedPrice(this.freeDeliveryOver - this.cart.total);
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        },
        formattedMinimalOrder() {
            return this.formattedPrice(this.minimalOrderValue);
        },
        selectedSlotId() {
            if (this.selectedSlot) {
                return this.selectedSlot.id;
            } else {
                return null;
            }
        }
    }
});