import {Controller} from "stimulus";

export default class extends Controller {

    initialize() {
    }

    keypress(e) {
        if (e.charCode < 1040 || e.charCode > 1103) {
            if (e.charCode != 0 && e.charCode != 32 && e.charCode != 45) {

                $(this.element).tooltip({
                    title: "Позволени са само символи на кирилица.",
                });
                $(this.element).tooltip('show');
                $(this.element).addClass('is-invalid');
                e.preventDefault();
            } else {
                $(this.element).tooltip('dispose');
                $(this.element).removeClass('is-invalid');
            }
        } else {
            $(this.element).tooltip('dispose');
            $(this.element).removeClass('is-invalid');
        }

    }
}