import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        this.current_element = this.element.closest('.product-item').querySelector('.product-gallery-image.product-' + this.element.dataset.product_id);
    }

    changeVariants() {
        this.current_element.src = this.element.dataset.image;
    }
}