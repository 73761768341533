import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ['field', 'wrapper'];

    initialize() {
        this.value = null;
        this.showOnValue = null;
        this.toggleElement = null;
        this.toggleElementRequired = 0;
        this.identifierId = null;
    }

    connect() {

        let field = this.fieldTarget;
        if ($(this.fieldTarget).is("input[type='radio']") || $(this.fieldTarget).is("input[type='checkbox']")) {
            this.value = $(this.fieldTarget).is(":checked");
        } else {
            this.value = this.fieldTarget.value;
        }

        this.showOnValue = $(this.element).data('show-on-value');
        this.toggleElement = $($(this.element).data('toggle-element'));
        this.toggleElementRequired = ($(this.element).data('toggle-element-required'));
        this.identifierId = ($(this.element).data('identifier-id'));

        this.handleChange();

        field.addEventListener('change', (event) => {


            if ($(event.target).is("input[type='radio']") || $(event.target).is("input[type='checkbox']")) {
                this.value = $(event.target).is(":checked");

            } else {
                this.value = event.target.value;
            }
            this.handleChange();
        });
    }
    handleChange() {
        let wrapper = this.wrapperTarget;

        if (this.toggleElement.length > 0) {
            this.toggleElement.toggle(this.fieldTarget.id == this.identifierId);
            this.toggleElement.find('input').attr('required', function (_, attr) {
                return $(this).is(':visible');
            });

        }

        console.log(this.value);

        if (this.value == this.showOnValue) {
            $(wrapper).show();
            $(wrapper).find("input, select").each(function (i, element) {
                $(element).attr('required', 'required');
                $(element).removeAttr('disabled');
            })
        } else {
            $(wrapper).hide();
            $(wrapper).find("input, select").each(function (i, element) {
                $(element).removeAttr('required');
                $(element).attr('disabled', 'disabled');
            })
        }
    }
}