import {Controller} from "stimulus"


export default class extends Controller {

    connect() {
        this.object = this.element.getAttribute('data-object');
        this.event = this.element.getAttribute("data-event");
        this.action = this.element.getAttribute("data-action");
        this.eventData = this.element.getAttribute("data-data");


        if (this.event && this.action) {
            this.prepareEvent();
        } else {
            this.prepareSimpleEvent();
        }
    }

    prepareEvent() {
        if (this.event === 'eec.detail') {
            this.eecDetail();
        }else if(this.event === 'eec.checkout'){
            this.eecCheckout();
        }else if(this.event === 'eec.purchase'){
            this.eecPurchase();
        }
    }

    prepareSimpleEvent() {

        if (this.eventData) {
            let layer = {
                [this.object]: JSON.parse(this.eventData)
            }
            this.pushData(layer);
        }
    }

    initialize() {
        this.dataLayer = window.dataLayer;
        this.isMain = this.element.getAttribute('data-main');

        if(this.isMain){
            EventManager.listen('gtm-event', payload => {
                this.onGtmEvent(payload);
           });
        }

    }

    onGtmEvent(data){

        this.event = data.event;
        this.action = data.action;
        this.eventData = data.eventData;

        if( this.event  === 'eec.add'){
            this.eecAdd();
        }else if(this.event === 'eec.remove'){
            this.eecRemove();
        }
    }

    eecCheckout(){

        let layer = {
            event: this.event,
        }

        this.pushData(layer);
    }

    eecPurchase(){
        let layer = {
            event: this.event,
            ecommerce: JSON.parse(this.eventData).ecommerce
        }

        this.pushData(layer);
    }

    eecRemove(){
        let layer = {
            event: this.event,
            ecommerce: {
                remove: {
                    actionField: {
                        list: this.action
                    },
                    products: [this.eventData]
                },
            }
        }
        this.pushData(layer);
    }

    eecAdd(){
        let layer = {
            event: this.event,
            ecommerce: {
                add: {
                    actionField: {
                        list: this.action
                    },
                    products: [this.eventData]
                },
            }
        }
        this.pushData(layer);
    }

    eecDetail() {
        let layer = {
            event: this.event,
            ecommerce:{
                detail: {
                    actionField: {
                        list: this.action
                    },
                    products: [JSON.parse(this.eventData)]
                },
            }

        }

        this.pushData(layer);
    }

    pushData(data) {
        this.dataLayer.push(data);
    }
}