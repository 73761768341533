import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        this.componentId = this.element.getAttribute('id');

        this.element.addEventListener('click', this.showImageGallery);

        EventManager.listen('image-gallery.select-image', payload => {
            if (payload.componentId === this.componentId) {
                this.element.value = payload.path;
            }
        });
    }

    showImageGallery() {
        EventManager.fire('add-component', {type: 'image-gallery', id: this.id});
    }
}